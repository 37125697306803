import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import classes from './LineLoader.module.css';

interface LineLoaderProps {
  className?: string;
}

const LineLoader: FC<LineLoaderProps> = ({ className }) => <div className={classNames(classes.loader, className)} />;

interface DebouncedLineLoaderProps extends LineLoaderProps {
  timeout?: number;
}

export const DebouncedLineLoader: FC<DebouncedLineLoaderProps> = ({ timeout = 500, className }) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setShow(true);
    }, timeout);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [timeout]);

  return show ? <LineLoader className={className} /> : null;
};

export default LineLoader;
