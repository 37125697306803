import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const surveysRoutes = (
  <Route path="surveys">
    <Route index {...createLazyRoute(module, m => m.surveyListRoute)} />
    <Route path="history" {...createLazyRoute(module, m => m.surveyListRoute)} />

    <Route path=":id">
      <Route index {...createLazyRoute(module, m => m.surveyDetailRoute)} />

      <Route path="flow" {...createLazyRoute(module, m => m.surveyFlowContextRoute)}>
        <Route index {...createLazyRoute(module, m => m.surveyFlowQuestionRoute)} />
        <Route path=":questionId" {...createLazyRoute(module, m => m.surveyFlowQuestionRoute)} />
        <Route path="validate" {...createLazyRoute(module, m => m.surveyFlowValidateRoute)} />
      </Route>

      <Route path="success" {...createLazyRoute(module, m => m.surveyFlowSuccessRoute)} />
    </Route>
  </Route>
);

export default surveysRoutes;
