import { Route } from 'react-router-dom';
import alertReportsRoutes from '@modules/pro/diseases/alert-reports/routes';
import { createLazyRoute } from '@core/router';

const diseasesDashboardRoute = () => import('@modules/pro/diseases/DiseasesDashboardPage');

const diseasesRoutes = (
  <Route path="diseases">
    <Route index {...createLazyRoute(diseasesDashboardRoute, m => m.default)} />

    {alertReportsRoutes}
  </Route>
);

export default diseasesRoutes;
