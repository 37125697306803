import { To } from 'react-router-dom';
import { QueryUtils } from '@shared/utils/queries';

export namespace AuthUtils {
  export function createLoginLink(withReferrer: boolean): To {
    const referrer = withReferrer ? window.location.pathname : undefined;

    return {
      pathname: '/login',
      search: QueryUtils.stringify({ referrer }),
    };
  }
}
