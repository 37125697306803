import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import loginWithTokenRoute from '@modules/auth/LoginWithTokenPage';
import loginRoute from '@modules/auth/LoginPage';
import logoutRoute from '@modules/auth/logout';

export const authRoutes = (
  <>
    <Route path="login">
      <Route index {...createRoute(loginRoute)} />
      <Route path="token/:token" {...createRoute(loginWithTokenRoute)} />
    </Route>

    <Route path="logout" {...createRoute(logoutRoute)} />
  </>
);

export default authRoutes;
