import { z } from 'zod';
import { Email, NonEmptyString } from '@shared/schemas';

export namespace Activation {
  export const Token = z.coerce.string().brand('ActivationToken');
  export type Token = z.infer<typeof Token>;

  export interface Infos {
    email: Email;
  }

  export const Form = z
    .object({
      password: NonEmptyString,
      confirmPassword: z.string().min(1, 'Champs requis'),
    })
    .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
      path: ['confirmPassword'],
      message: 'Les mots de passe ne correspondent pas',
    });
  export type Form = z.infer<typeof Form>;

  export const Params = z.object({
    password: NonEmptyString,
  });
  export type Params = z.infer<typeof Params>;
}
