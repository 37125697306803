import { HttpStatusCode } from 'axios';

export function getErrorTitle(status: number) {
  switch (status) {
    case HttpStatusCode.NotFound:
      return 'La page que vous cherchez est introuvable';
    case HttpStatusCode.Unauthorized:
      return "Vous n'êtes pas autorisés à accéder à cette section";
    case HttpStatusCode.Forbidden:
      return "Vous n'avez pas les droits suffisants pour accéder à cette page.";
    default:
      return `Une erreur ${status} est survenue`;
  }
}
