import { z } from 'zod';

export namespace Company {
  export const Id = z.coerce.number().int().positive().brand('CompanyId');
  export type Id = z.infer<typeof Id>;

  export enum Kind {
    Covergarden = 'covergarden',
    SupplierProducer = 'supplier_producer',
    SupplierProvider = 'supplier_provider',
    Landscaper = 'landscaper',
    GreenKeeper = 'greenkeeper',
    Collectivity = 'collectivity',
    Agref = 'agref',
    Fredon = 'fredon',
    Unep = 'unep',
  }
}
