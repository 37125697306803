import { Effect, Option, pipe, Predicate, ReadonlyArray } from 'effect';
import { MobileInstance } from '@core/mobile/config';

export class AndroidInstance {
  private constructor(
    private config: Required<AndroidInstance.Config>,
    private signal: AbortSignal,
  ) {}

  private originCheckPredicate: Predicate.Predicate<MessageEvent> = ({ origin }) =>
    origin === `android-app://${window.location.hostname}/${this.config.package}`;

  init(onPort: (port: MobileInstance.Port) => void) {
    const onMessage = (event: MessageEvent) =>
      pipe(
        event,
        Option.liftPredicate(this.originCheckPredicate),
        Option.flatMap(({ ports }) => ReadonlyArray.head(ports)),
        Effect.tap(port => Effect.sync(() => onPort(AndroidInstance.Port.create(port, this.signal)))),
        Effect.runSyncExit,
      );

    window.addEventListener('message', onMessage, { signal: this.signal });
  }

  static create(config: AndroidInstance.Config, signal: AbortSignal) {
    return new AndroidInstance(config, signal);
  }
}

export namespace AndroidInstance {
  export class Port implements MobileInstance.Port {
    private constructor(private port: MessagePort) {}

    postMessage(message: any) {
      return this.port.postMessage(message);
    }

    listen(callback: (data: any) => void, signal: AbortSignal) {
      this.port.addEventListener('message', e => callback(e.data), { signal });
    }

    platform = MobileInstance.Platform.Android;

    static create(port: MessagePort, signal: AbortSignal) {
      signal.addEventListener('abort', () => port.close(), { once: true });
      //warning on usage: https://bugs.chromium.org/p/chromium/issues/detail?id=515732#c1
      port.start();
      return new Port(port);
    }
  }
  export interface Config {
    package: string;
  }

  export const logStyle = 'background-color: green; border: 2px solid green; color: white; font-size: 1.2em;';
}
