import { Order, pipe, ReadonlyArray } from 'effect';

export namespace OrderUtils {
  export interface Orderable {
    order: number;
  }

  const ordByOrder = pipe(
    Order.number,
    Order.mapInput((item: Orderable) => item.order),
  );

  export function sortListByOrder<T extends Orderable>(list: Array<T>): Array<T> {
    return ReadonlyArray.sort(list, ordByOrder);
  }

  export function ordFromOrdering<Key extends keyof any>(ordering: Record<Key, number>): Order.Order<Key> {
    return Order.make((first, second) => {
      const firstOrdering = ordering[first];
      const secondOrdering = ordering[second];

      return firstOrdering < secondOrdering ? -1 : firstOrdering > secondOrdering ? 1 : 0;
    });
  }
}
