import React from 'react';
import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

const partnersRoutes = (
  <Route path="partners">
    <Route index {...createLazyRoute(module, m => m.partnersListRoute)} />
    <Route path="invite" {...createLazyRoute(module, m => m.invitePartnersListRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.partnerDetailPage)} />
  </Route>
);

export default partnersRoutes;
