import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

const newsRoutes = (
  <Route path="news">
    <Route index {...createLazyRoute(module, m => m.newsListRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.newsDetailRoute)} />
    <Route path="subscriptions" {...createLazyRoute(module, m => m.newsSubscriptionsRoute)} />
  </Route>
);

export default newsRoutes;
