import { Effect, Effect as EffectNamespace } from 'effect';
import { HttpStatusCode } from 'axios';
export import MobileStatusCode = HttpStatusCode;
import { MobileInstance } from '@core/mobile/config';

export namespace Mobile {
  export class Error<T = unknown> {
    constructor(
      public readonly status: MobileStatusCode,
      public readonly message?: string,
      public readonly type?: string,
      public readonly requestId?: MobileInstance.RequestId,
      public readonly data?: T,
    ) {}

    log = () =>
      Effect.logError(
        `[MobileError] status ${this.status}, type: ${this.type}, message: ${this.message}, request: ${this.requestId}`,
      );

    isDownError() {
      return this.status > 500;
    }

    toJson() {
      return {
        status: this.status,
        message: this.message,
        type: this.type,
        requestId: this.requestId,
        data: this.data,
      };
    }

    fail = (): Effect.Effect<never, Error<T>, never> => Effect.fail(this);

    static fromStatusCode<E = unknown>(status: MobileStatusCode, message?: string): Error<E> {
      return new Error(status, message);
    }

    static readonly default = Error.fromStatusCode(MobileStatusCode.InternalServerError);
    static readonly notFound = Error.fromStatusCode(MobileStatusCode.NotFound);
    static readonly forbidden = Error.fromStatusCode(MobileStatusCode.Forbidden);
  }
  export type Effect<R = unknown, E = unknown> = EffectNamespace.Effect<never, Error<E>, R>;
}
