import { Utils } from '@shared/utils/model';
import { Threshold } from '@modules/iot/model';
import { Measure } from '@modules/measures/model';
import { SinafisSchema } from '@modules/pro/sensors/sinafis/schema';
import { z } from 'zod';
import { OrderUtils } from '@shared/utils/order';

export namespace Sinafis {
  export namespace Probe {
    export enum Identifier {
      Transmitter = 'transmitter',
      Ground1 = 'ground-1',
      Ground2 = 'ground-2',
      Leaf = 'leaf',
    }

    export const identifierLabel: Record<Identifier, string> = {
      [Identifier.Transmitter]: 'Transmetteur',
      [Identifier.Ground1]: 'Capteur sol 1',
      [Identifier.Ground2]: 'Capteur sol 2',
      [Identifier.Leaf]: 'Capteur Foliaire',
    };

    const identifierOrdering: Record<Identifier, number> = {
      [Identifier.Transmitter]: 1,
      [Identifier.Ground1]: 2,
      [Identifier.Ground2]: 3,
      [Identifier.Leaf]: 4,
    };

    export const identifierOrd = OrderUtils.ordFromOrdering(identifierOrdering);

    export const identifierConfig = {
      [Identifier.Transmitter]: 'transmitter',
      [Identifier.Ground1]: 'ground1',
      [Identifier.Ground2]: 'ground2',
      [Identifier.Leaf]: 'leaf',
    } satisfies Record<Identifier, Config.Identifier>;

    export const identifierProbeAlerts: Record<Identifier, Array<Measure.Type>> = {
      [Identifier.Transmitter]: [Measure.Type.Humidity, Measure.Type.Battery, Measure.Type.Temperature],
      [Identifier.Ground1]: [Measure.Type.Humidity, Measure.Type.Temperature],
      [Identifier.Ground2]: [Measure.Type.Humidity, Measure.Type.Temperature],
      [Identifier.Leaf]: [Measure.Type.Humidity],
    };
  }

  export namespace Config {
    export interface Transmitter {
      height: Utils.Length;
      humidityThreshold: Threshold.HumidityScale;
      temperatureThreshold: Threshold.TemperatureScale;
    }

    export interface Ground {
      depth: Utils.Length;
      humidityThreshold: Threshold.HumidityScale;
      temperatureThreshold: Threshold.TemperatureScale;
    }

    export interface Leaf {
      height: Utils.Length;
      humidityThreshold: Threshold.HumidityScale;
    }

    export type Identifier = keyof Config;

    export const configIdentifier = {
      transmitter: Probe.Identifier.Transmitter,
      ground1: Probe.Identifier.Ground1,
      ground2: Probe.Identifier.Ground2,
      leaf: Probe.Identifier.Leaf,
    } satisfies Record<Identifier, Probe.Identifier>;

    export enum ProbeMapper {
      Probe1 = 1,
      Probe2,
      Probe3,
      Probe4,
    }
    export interface MeasureProbeMapping {
      ground1: ProbeMapper | null;
      ground2: ProbeMapper | null;
      leaf: ProbeMapper | null;
    }

    export interface ExtendedMeasureProbeMapping {
      transmitter: unknown;
      ground1: unknown;
      ground2: unknown;
      leaf: unknown;
    }

    export interface Remote {
      measureMappings: MeasureProbeMapping;
    }

    const configOrdering: Record<Identifier, number> = {
      transmitter: 1,
      ground1: 2,
      ground2: 3,
      leaf: 4,
    };

    export const configOrd = OrderUtils.ordFromOrdering(configOrdering);
  }

  export interface Config {
    transmitter: Sinafis.Config.Transmitter;
    ground1: Sinafis.Config.Ground | null;
    ground2: Sinafis.Config.Ground | null;
    leaf: Sinafis.Config.Leaf | null;
  }

  export type RegisterParams = z.infer<typeof SinafisSchema.registerSinafisSchema>;
}
