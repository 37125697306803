import React, { FC } from 'react';
import { defineRoute, redirect } from '@core/router';
import { ActionIcon, Box, Group, Image, Title, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import logoInversed from '@assets/logos/logo-inversed.svg';
import { Activation } from '@modules/activation/model';
import ActivationForm from '@modules/activation/components/form/ActivationForm';

import classes from './ActivationPage.module.css';
import { ActivationService } from '@modules/activation/service';
import { defineAction, useAction } from '@core/router/action';
import { z } from 'zod';
import { Effect, pipe } from 'effect';
import { ActivationUtils } from '@modules/activation/utils';
import { QueryUtils } from '@shared/utils/queries';

const queries = z.object({
  referrer: z.string().catch(() => '/'),
});

const params = z.object({
  activationToken: Activation.Token,
});

const actions = {
  activate: defineAction({
    type: 'activate',
    params,
    payload: Activation.Params,
    handler: ({ params, payload, request }) =>
      Effect.gen(function* (_) {
        yield* _(ActivationService.activate(params.activationToken, payload));

        const { referrer } = yield* _(QueryUtils.parseFromRequest(request, queries));

        yield* _(redirect(referrer));
      }),
  }),
};

const ActivationPage: FC = () => {
  const [loading, activate] = useAction(actions.activate);

  const handleSubmit = (body: Activation.Form) => {
    return pipe(activate(ActivationUtils.mapFormToParams(body)), Effect.runPromise);
  };

  return (
    <Box bg="tertiary.8" w="100%" c="white" className={classes.container}>
      <ActionIcon mt={20} ml={20} component={Link} to="/" replace variant="transparent" c="white">
        <IconArrowLeft size={22} />
      </ActionIcon>

      <Group p={20} justify="center">
        <Image src={logoInversed} alt="Logo" w={180} />
      </Group>

      <Box px={20} pb={25}>
        <Title fz={20} fw={600}>
          Choisir votre mot de passe
        </Title>

        <Text pt={20} fz={14}>
          Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un
          caractère spécial.
        </Text>
      </Box>

      <Box px={20}>
        <ActivationForm onSubmit={handleSubmit} loading={loading} />
      </Box>
    </Box>
  );
};

const activationRoute = defineRoute({
  actions,
  element: <ActivationPage />,
});

export default activationRoute;
