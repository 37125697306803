import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const signUpRoutes = (
  <Route path="sign-up">
    <Route index {...createLazyRoute(module, m => m.signUpKindSelectRoute)} />
    <Route path=":kind" {...createLazyRoute(module, m => m.signUpKindDetailRoute)} />
    <Route path=":kind/form" {...createLazyRoute(module, m => m.signUpFormRoute)} />
  </Route>
);

export default signUpRoutes;
