import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const subscriptionsRoutes = (
  <Route path="subscriptions">
    <Route index {...createLazyRoute(module, m => m.subscriptionsListRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.subscriptionDetailRoute)} />
  </Route>
);

export default subscriptionsRoutes;
