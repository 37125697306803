import React, { FC, useEffect } from 'react';
import { Http } from '@core/http';

import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { Box, Button, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { getErrorTitle } from '@shared/components/error-page/utils';
import config from '@root/config';
import { HttpStatusCode } from 'axios';

import classes from './ErrorPage.module.css';

interface DefaultErrorContentProps {
  title: string;
}

const DefaultErrorContent: FC<DefaultErrorContentProps> = ({ title }) => {
  return (
    <Box className={classes.container}>
      <Title>{title}</Title>

      <Text py={30} px={0}>
        Nous vous invitons à revenir à l'accueil
      </Text>

      <Button component={Link} to="/">
        Revenir à l'accueil
      </Button>
    </Box>
  );
};

const UnauthorizedErrorContent: FC = () => {
  return (
    <Box className={classes.container}>
      <Title>Vous n'êtes pas authentifié</Title>

      <Text py={30} px={0}>
        Nous vous invitons à revenir sur l'application
      </Text>

      <Button component={Link} to={config.VITE_PLATFORM_URL}>
        Revenir sur l'application
      </Button>
    </Box>
  );
};

interface InternalErrorContentProps {
  status: number;
}

const InternalErrorContent: FC<InternalErrorContentProps> = ({ status }) => {
  switch (status) {
    case HttpStatusCode.Unauthorized:
      return <UnauthorizedErrorContent />;
    default:
      return <DefaultErrorContent title={getErrorTitle(status)} />;
  }
};

interface ErrorPageProps {
  error?: Http.Error;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = Http.Error.notFound }) => {
  const internalError = useRouteError();

  useEffect(() => {
    if (internalError) {
      if (!isRouteErrorResponse(internalError)) {
        Sentry.captureException(internalError, {
          level: 'error',
        });
      } else if (
        ![HttpStatusCode.NotFound, HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(internalError.status)
      ) {
        Sentry.captureMessage(`[ErrorPage] capture response with ${internalError.status} code`, {
          level: 'error',
          extra: { response: internalError },
        });
      }
    }
  }, [internalError]);

  return isRouteErrorResponse(internalError) ? (
    <InternalErrorContent status={internalError.status} />
  ) : internalError ? (
    <DefaultErrorContent title="Erreur technique interne" />
  ) : (
    <DefaultErrorContent title={getErrorTitle(error.status)} />
  );
};

export default ErrorPage;
