import { Effect, Function } from 'effect';
import React, { FC, useLayoutEffect, useState } from 'react';
import { Loader, Modal, Stack, Text, Title } from '@mantine/core';

const MOBILE_DOWN_EVENT_NAME = 'mobile-down';
const MOBILE_UP_EVENT_NAME = 'mobile-up';

const MobileDownIndicator: FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  useLayoutEffect(() => {
    const showIndicator = () => setOpened(true);
    const hideIndicator = () => setOpened(false);

    window.addEventListener(MOBILE_DOWN_EVENT_NAME, showIndicator);
    window.addEventListener(MOBILE_UP_EVENT_NAME, hideIndicator);

    return () => {
      window.removeEventListener(MOBILE_DOWN_EVENT_NAME, showIndicator);
      window.removeEventListener(MOBILE_UP_EVENT_NAME, hideIndicator);
    };
  }, []);

  return (
    <Modal size="md" opened={opened} onClose={Function.constVoid} withCloseButton={false} centered>
      <Stack p="md" align="center">
        <Title size="h3" ta="center">
          Connexion avec l'application interrompue
        </Title>

        <Loader size="lg" />

        <Text ta="center">Veuillez patienter nous essayons de rétablir la connexion.</Text>
      </Stack>
    </Modal>
  );
};

export const showMobileDownIndicator = Effect.sync(() => window.dispatchEvent(new Event(MOBILE_DOWN_EVENT_NAME)));
export const hideMobileDownIndicator = Effect.sync(() => window.dispatchEvent(new Event(MOBILE_UP_EVENT_NAME)));

export default MobileDownIndicator;
