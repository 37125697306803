import { Activation } from '@modules/activation/model';
import { Http, HttpService } from '@core/http';
import { OAuth } from '@core/oauth/model';

export namespace ActivationService {
  const URI = '/app/activation';

  export function getActivationInfo(activationToken: Activation.Token): Http.Effect<Activation.Infos> {
    return HttpService.get(`${URI}/${activationToken}`);
  }

  export function activate(activationToken: Activation.Token, params: Activation.Params): Http.Effect<OAuth.Tokens> {
    return HttpService.post(`${URI}/${activationToken}`, params);
  }
}
