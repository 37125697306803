import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

export const passwordResetRoutes = (
  <Route path="password-reset">
    <Route index {...createLazyRoute(module, m => m.forgotRoute)} />
    <Route path=":passwordResetToken" {...createLazyRoute(module, m => m.passwordResetRoute)} />
  </Route>
);
