import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const coachRoutes = (
  <Route path="coach">
    <Route index {...createLazyRoute(module, m => m.coachListRoute)} />
    <Route path="folder/:id" {...createLazyRoute(module, m => m.coachListRoute)} />
    <Route path="article/:id" {...createLazyRoute(module, m => m.coachArticleDetailRoute)} />
  </Route>
);

export default coachRoutes;
