import React from 'react';
import { createRoute } from '@core/router';
import { Route } from 'react-router-dom';
import activationRoute from './ActivationPage';

export const activationRoutes = (
  <Route path="activation">
    <Route path=":activationToken" {...createRoute(activationRoute)} />
  </Route>
);

export default activationRoutes;
