import { z } from 'zod';
import { NonEmptyString } from '@shared/schemas';
import { Geo } from '@shared/modules/geo/model';
import { Zone } from '@modules/iot/zones/model';

export namespace SensorFilter {
  export const Id = z.string().uuid().brand('SensorFilterId');
  export type Id = z.infer<typeof Id>;

  export const CreateParams = z.object({
    name: NonEmptyString,
    zoneId: Zone.Id.nullable(),
    location: Geo.CitySchema.nullable(),
  });

  export type CreateParams = z.infer<typeof CreateParams>;
}

export interface SensorFilter {
  id: SensorFilter.Id;
  name: NonEmptyString;
  zoneId: Zone.Id | null;
  location: Geo.City | null;
}
