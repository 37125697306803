import z from 'zod';
import { Order } from 'effect';

export namespace Utils {
  export const PostalCode = z.string().min(1).brand<'PostalCode'>();
  export type PostalCode = z.infer<typeof PostalCode>;

  export const Percent = z.number().brand<'Percent'>();
  export type Percent = z.infer<typeof Percent>;
  export const percentOrd: Order.Order<Percent> = Order.number;

  export const Temperature = z.number().brand<'Temperature'>();
  export type Temperature = z.infer<typeof Temperature>;
  export const temperatureOrd: Order.Order<Temperature> = Order.number;

  export const Length = z.number().brand<'Length'>();
  export type Length = z.infer<typeof Length>;

  export const GPSCoordinates = z.object({
    latitude: z.number().min(-90).max(90),
    longitude: z.number().min(-180).max(180),
  });
  export type GPSCoordinates = z.infer<typeof GPSCoordinates>;

  export enum ChronoUnit {
    Nanos = 'NANOS',
    Micros = 'MICROS',
    Millis = 'MILLIS',
    Seconds = 'SECONDS',
    Minutes = 'MINUTES',
    Hours = 'HOURS',
    HalfDays = 'HALF_DAYS',
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS',
    Years = 'YEARS',
    Decades = 'DECADES',
    Centuries = 'CENTURIES',
    Millennia = 'MILLENNIA',
    Eras = 'ERAS',
    Forever = 'FOREVER',
  }
}
