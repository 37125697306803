import { useFetcher, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Loader, LoaderReturnType } from '@core/router/loader/index';

import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Option } from 'effect';

/**
 * Retourne les données du loader de la route en cours
 *
 * Ce loader est synchrone
 */
export function useLoader<L>(): LoaderReturnType<L> {
  return useLoaderData() as LoaderReturnType<L>;
}

/**
 * Retourne les données du loader parent ayant l'id passé en paramètre
 *
 * Ce loader est synchrone
 *
 * @param id - Identifiant du loader parent
 */
export function useParentLoader<
  L extends Loader<any, any, any>,
  ID extends string = L extends Loader<any, any, infer ID> ? ID : never,
>(id: ID): LoaderReturnType<L> {
  return useRouteLoaderData(id) as LoaderReturnType<L>;
}

/**
 * Retourne les données du loader d'une autre route.
 *
 * Peut être pratique pour les apis communes ou le chargement de reférentiels
 *
 * Ce loader est asynchrone. Mais si le loader throw une réponse, la page d'erreur globale sera affiché. Sinon à l'utilisateur de ce hooks de gérer cette erreur.
 *
 * @param url
 */
export function useOtherLoader<L, R = LoaderReturnType<L>>(
  url: string,
): [Option.Option<R>, () => void, boolean, Dispatch<SetStateAction<Option.Option<R>>>] {
  const [data, setData] = useState<Option.Option<R>>(Option.none);

  const fetcher = useFetcher<R>();

  const fetcherRef = useRef(fetcher);

  fetcherRef.current = fetcher;

  const loading = fetcher.state !== 'idle';

  const fetchData = useCallback(() => fetcherRef.current.submit(null, { method: 'get', action: url }), [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   * Sync data with fetcher return
   */
  useEffect(() => {
    setData(Option.fromNullable(fetcherRef.current.data));
  }, [fetcherRef.current.data]);

  return [data, fetchData, loading, setData];
}
