import { Mobile } from '@core/mobile/model';
import { MobileInstance, MobileResponse, defaultMobileInstance } from '@core/mobile/config';
import { Effect, pipe, Schedule } from 'effect';
import { hideMobileDownIndicator, showMobileDownIndicator } from '@core/mobile/components/MobileDownIndicator';

function sendRequest<R, E>(
  request: Mobile.Effect<MobileResponse<R>, E>,
  raw?: true,
): Mobile.Effect<R | MobileResponse<R>, E> {
  const retryPolicy = pipe(
    Schedule.union(Schedule.exponential('500 millis'), Schedule.spaced('2 seconds')),
    Schedule.whileInput<Mobile.Error>(err => err.isDownError()),
    Schedule.tapOutput(([, occur]) => (occur === 1 ? showMobileDownIndicator : Effect.unit)),
  );

  return pipe(
    request,
    Effect.retry(retryPolicy),
    Effect.map(res => (raw ? res : res.data)),
    Effect.onExit(() => hideMobileDownIndicator),
  );
}

function get<R = unknown, E = unknown>(type: string, data?: any): Mobile.Effect<R, E>;
function get<R = unknown, E = unknown>(type: string, data: any, raw: true): Mobile.Effect<MobileResponse<R>, E>;
function get<R = unknown, E = unknown>(type: string, data?: any, raw?: true): Mobile.Effect<R | MobileResponse<R>, E> {
  return sendRequest(defaultMobileInstance.send(MobileInstance.Method.Get, type, data), raw);
}

function post<R = unknown, E = unknown>(type: string, data?: any): Mobile.Effect<R, E>;
function post<R = unknown, E = unknown>(type: string, data: any, raw: true): Mobile.Effect<MobileResponse<R>, E>;
function post<R = unknown, E = unknown>(type: string, data?: any, raw?: true): Mobile.Effect<R | MobileResponse<R>, E> {
  return sendRequest(defaultMobileInstance.send(MobileInstance.Method.Post, type, data), raw);
}

export const MobileService = {
  get,
  post,
};
