import { Http, HttpService } from '@core/http';
import { Push } from '@shared/modules/push/model';
import config from '@root/config';

export namespace PushService {
  const URI = `${config.VITE_API_PREFIX}/push`;

  export function register(params: Push.RegisterParams): Http.Effect {
    return HttpService.post(`${URI}/register`, params);
  }
}
