import { OAuth } from '@core/oauth/model';
import { HttpStatusCode } from 'axios';

export namespace OAuthUtils {
  import OAuthHttpError = OAuth.HttpError;
  import OAuthErrorCode = OAuth.ErrorCode;

  const defaultMessage = 'Une erreur technique est survenue.';

  export function getOAuthErrorMessage(error: OAuthHttpError): string {
    switch (error.status) {
      case HttpStatusCode.BadRequest:
        switch (error.data?.error) {
          case OAuthErrorCode.InvalidGrant:
          case OAuthErrorCode.UnsupportedGrantType:
            return 'Email ou mot de passe incorrect.';
          case OAuthErrorCode.AccessDenied:
            return "Vous n'êtes pas autorisé à accéder à cette application.";
          default:
            return defaultMessage;
        }
      default:
        return defaultMessage;
    }
  }
}
