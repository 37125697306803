import { ReactNode } from 'react';
import { showNotification } from '@mantine/notifications';
import { Cause, Effect, Exit, Option, pipe } from 'effect';
import { SentryUtils } from '@shared/modules/sentry/utils';
import { HttpStatusCode } from 'axios';

export type FlashNotificationType = 'error' | 'success';

const technicalErrorMessage = 'Une erreur technique est survenue';

export function showFlashNotification(type: FlashNotificationType, message: ReactNode) {
  return Effect.sync(() => showNotification({ message, color: type === 'success' ? 'green' : 'red' }));
}

export function showTechnicalErrorFlashNotification() {
  return showFlashNotification('error', technicalErrorMessage);
}

export interface HttpFlashNotificationOptions {
  hideError?: true | Array<HttpStatusCode>;
  successMessage?: ReactNode;
}

export interface FlashNotificationOptions<E> {
  error?: boolean | ReactNode | ((error: E) => boolean | ReactNode);
  success?: ReactNode;
}

export function showFlashFromExit<R, E>({ error = true, success }: FlashNotificationOptions<E> = {}): (
  res: Exit.Exit<E, R>,
) => Effect.Effect<never, never, void> {
  return res => {
    if (Exit.isSuccess(res) && success) {
      return showFlashNotification('success', success);
    } else if (Exit.isFailure(res)) {
      const failureError = Cause.failureOption(res.cause);

      const getMessage = (
        message: boolean | ReactNode | ((error: E) => boolean | ReactNode),
      ): Effect.Effect<never, never, Option.Option<ReactNode>> => {
        const technicalErrorMessageEffect = pipe(
          Effect.succeed(technicalErrorMessage),
          Effect.tap(() =>
            SentryUtils.logMessage('[flash] show flash notification with cause', 'error', { cause: res.cause }),
          ),
          Effect.option,
        );

        if (typeof message === 'function') {
          return Option.match(failureError, {
            onSome: error => getMessage(message(error)),
            onNone: () => technicalErrorMessageEffect,
          });
        }

        switch (message) {
          case true:
            return technicalErrorMessageEffect;
          case false:
            return Effect.succeed(Option.none());
          default:
            return Effect.succeed(Option.some(message));
        }
      };

      const message = getMessage(error);

      return pipe(
        message,
        Effect.flatMap(
          Option.match({
            onSome: message => showFlashNotification('error', message),
            onNone: () => Effect.unit,
          }),
        ),
      );
    }

    return Effect.unit;
  };
}
