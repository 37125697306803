import { MantineColor } from '@mantine/core';
import z from 'zod';
import { Utils } from '@shared/utils/model';

export namespace Threshold {
  import Percent = Utils.Percent;
  import Temperature = Utils.Temperature;

  export enum Level {
    None = 'none',
    Alert = 'alert',
    Critical = 'critical',
  }

  export const levelLabel: Record<Level, string> = {
    [Level.None]: 'None',
    [Level.Alert]: 'Alerte',
    [Level.Critical]: 'Critique',
  };

  export const levelColor: Record<Level, MantineColor> = {
    [Level.None]: 'green',
    [Level.Alert]: 'orange',
    [Level.Critical]: 'red',
  };

  interface Limit<Value, Level> {
    until: Value;
    level: Level;
  }

  export interface Scale<Value extends number, Level> {
    levels: Array<Limit<Value, Level>>;
    last: Level;
  }

  export type HumidityScale = Scale<Utils.Percent, Level>;
  export type TemperatureScale = Scale<Utils.Temperature, Level>;

  export const defaultHumidityScale: HumidityScale = {
    last: Level.None,
    levels: [
      { level: Level.Critical, until: Percent.parse(0.33) },
      { level: Level.Alert, until: Percent.parse(0.66) },
    ],
  };

  export const defaultTemperatureScale: TemperatureScale = {
    last: Level.Critical,
    levels: [
      { level: Level.None, until: Temperature.parse(10) },
      { level: Level.Alert, until: Temperature.parse(20) },
    ],
  };
}

export namespace HubApi {
  export const SensorId = z.coerce.number().brand('HubApiSensorId');
  export type SensorId = z.infer<typeof SensorId>;
}
