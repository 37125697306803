import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';
import React from 'react';

const module = () => import('./module');

const emergencyNumbersRoutes = (
  <Route path="emergency-numbers">
    <Route index {...createLazyRoute(module, m => m.emergencyNumbersListRoute)} />
  </Route>
);

export default emergencyNumbersRoutes;
