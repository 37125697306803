import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const catalogsRoutes = (
  <Route path="catalogs">
    <Route index {...createLazyRoute(module, m => m.catalogsListRoute)} />
    <Route path="archived" {...createLazyRoute(module, m => m.archivedCatalogsListRoute)} />

    <Route path=":offerId">
      <Route index {...createLazyRoute(module, m => m.catalogDetailRoute)} />
      <Route path="folders/:folderId" {...createLazyRoute(module, m => m.catalogDetailRoute)} />

      <Route path="products/:catalogProductId" {...createLazyRoute(module, m => m.catalogProductDetailRoute)} />
    </Route>

    <Route path="drafts">
      <Route index {...createLazyRoute(module, m => m.draftCatalogsListRoute)} />

      <Route path=":catalogId">
        <Route index {...createLazyRoute(module, m => m.draftCatalogDetailRoute)} />
        <Route path="folders/:folderId" {...createLazyRoute(module, m => m.draftCatalogDetailRoute)} />

        <Route path="products/:catalogProductId" {...createLazyRoute(module, m => m.draftCatalogProductDetailRoute)} />
      </Route>
    </Route>
  </Route>
);

export default catalogsRoutes;
