import React, { FC, PropsWithChildren, Suspense } from 'react';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { AppShell, Group, Stack, Text, Title } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';

const MAX_SCREEN_WIDTH = 1080;

const LayoutLock: FC = () => (
  <Stack justify="center" w="100%" p="md" ta="center" style={{ flex: '1 1 auto' }}>
    <Group justify="center">
      <IconAlertTriangle size={50} />
    </Group>

    <Title>On dirait que vous êtes sur un ordinateur.</Title>
    <Text>
      Pour une meilleure expérience utilisateur, nous vous invitons à vous connecter depuis un appareil mobile.
    </Text>
  </Stack>
);

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const isLocked = useMediaQuery(`(min-width: ${MAX_SCREEN_WIDTH}px)`, undefined, { getInitialValueInEffect: false });

  return (
    <AppShell>
      <AppShell.Main display="flex">
        <Suspense fallback={<DebouncedLineLoader />}>{isLocked ? <LayoutLock /> : children}</Suspense>
      </AppShell.Main>
    </AppShell>
  );
};

export default Layout;
