import { z } from 'zod';
import { IosInstance } from '@core/mobile/ios';
import { AndroidInstance } from '@core/mobile/android';
import { v4 as uuid } from 'uuid';
import { Console, Effect, identity, pipe, Option } from 'effect';
import { MobileStatusCode, Mobile } from '@core/mobile/model';
import config from '@root/config';

export class MobileInstance {
  private controller = new AbortController();
  private port: MobileInstance.Port | null = null;

  private constructor(public config: Required<MobileInstance.CreateDefaults>) {}

  init() {
    const android = AndroidInstance.create(this.config.android, this.controller.signal);

    android.init(port => {
      this.port = port;
    });

    const ios = IosInstance.create(this.controller.signal);

    ios.init(port => {
      this.port = port;
    });
  }

  private getResponse<R, E>(
    port: MobileInstance.Port,
    request: MobileRequest,
  ): Effect.Effect<never, Mobile.Error<E>, MobileResponse<R>> {
    return Effect.async(resume => {
      const controller = new AbortController();
      const listener = (data: string) =>
        pipe(
          Effect.try({
            try: () => JSON.parse(data) as MobileResponse<R>,
            catch: () => resume(Effect.fail(Mobile.Error.fromStatusCode(MobileStatusCode.UnprocessableEntity))),
          }),
          Effect.filterOrDie(response => response.request.id === request.id, identity),
          Effect.tap(response => {
            controller.abort('Response received');
            if (response.status > 400) resume(Effect.fail(Mobile.Error.fromStatusCode(response.status)));
            else resume(Effect.succeed<MobileResponse<R>>(response));
          }),
          Effect.runSyncExit,
        );

      port.listen(listener, controller.signal);
    });
  }

  send<D = any, R = unknown, E = unknown>(method: MobileInstance.Method, type: string, data: D) {
    const request: MobileRequest = {
      id: uuid() as MobileInstance.RequestId,
      method,
      content: { type, data },
    };

    const onPortEnabled = (port: MobileInstance.Port) => {
      port.postMessage(this.config.serializer(request));
      return this.getResponse<R, E>(port, request);
    };

    const onMissingPort = () => Effect.fail(Mobile.Error.fromStatusCode<E>(MobileStatusCode.ServiceUnavailable));

    const logger = MobileLogger.create(method, type, request.id);

    return pipe(
      Effect.fromNullable(this.port),
      Effect.matchEffect({
        onSuccess: port =>
          pipe(
            logger.init(port.platform),
            Effect.flatMap(() => onPortEnabled(port)),
            Effect.tapBoth({
              onFailure: logger.error(port.platform),
              onSuccess: logger.success(port.platform),
            }),
          ),
        onFailure: () => pipe(logger.unknown(), Effect.flatMap(onMissingPort)),
      }),
    );
  }

  static create(config: MobileInstance.CreateDefaults) {
    const mobile = new MobileInstance({
      ...config,
      serializer: config.serializer ?? MobileInstance.defaultSerializer,
    });
    mobile.init();
    return mobile;
  }

  close() {
    this.controller.abort();
  }

  get platform() {
    return pipe(
      Option.fromNullable(this.port),
      Option.map(({ platform }) => platform),
      Option.getOrNull,
    );
  }
}

export class MobileLogger {
  private constructor(
    private method: MobileInstance.Method,
    private type: string,
    private id: MobileInstance.RequestId,
  ) {}

  static create(method: MobileInstance.Method, type: string, id: MobileInstance.RequestId) {
    return new MobileLogger(method, type, id);
  }

  private unknownLogStyle = 'background-color: dimgrey; border: 2px solid dimgrey; color: white; font-size: 1.2em;';
  unknown = () =>
    Console.debug(
      `%cUnknown Platform`,
      this.unknownLogStyle,
      `Request ${this.method.toUpperCase()} "${this.type}" (${this.id})`,
    );

  init = (platform: MobileInstance.Platform) =>
    Console.debug(
      `%c${MobileInstance.platformLabel[platform]}`,
      MobileInstance.plaformLogStyle[platform],
      `Init request ${this.method.toUpperCase()} "${this.type}" (${this.id})`,
    );

  success = (platform: MobileInstance.Platform) => (res: MobileResponse) =>
    Console.debug(
      `%c${MobileInstance.platformLabel[platform]}`,
      MobileInstance.plaformLogStyle[platform],
      `Request ${this.method.toUpperCase()} "${this.type}" succeed (${this.id})`,
      res,
    );

  error = (platform: MobileInstance.Platform) => (err: Mobile.Error) =>
    Console.error(
      `%c${MobileInstance.platformLabel[platform]}`,
      MobileInstance.plaformLogStyle[platform],
      `Request ${this.method.toUpperCase()} "${this.type}" failed (${this.id})`,
      err,
    );
}

export namespace MobileInstance {
  export enum Platform {
    Android = 'android',
    IOS = 'ios',
  }
  export const platformLabel: Record<Platform, string> = {
    [Platform.Android]: 'Android',
    [Platform.IOS]: 'IOS',
  };

  export const plaformLogStyle: Record<Platform, string> = {
    [Platform.Android]: AndroidInstance.logStyle,
    [Platform.IOS]: IosInstance.logStyle,
  };

  export interface Port {
    postMessage(message: any): void;
    listen(callback: (data: string) => void, signal: AbortSignal): void;
    platform: Platform;
  }

  export const RequestId = z.string().uuid().brand('AndroidRequestId');
  export type RequestId = z.infer<typeof RequestId>;
  export enum Method {
    Get = 'get',
    Post = 'post',
  }

  export const defaultSerializer = (data: any) => JSON.stringify(data);

  export interface RequestContent<D = any> {
    type: string;
    data: D;
  }

  export interface CreateDefaults {
    serializer?: (data: any) => string;
    android: AndroidInstance.Config;
  }
}

export interface MobileRequest<D = any> {
  id: MobileInstance.RequestId;
  method: MobileInstance.Method;
  content: MobileInstance.RequestContent<D>;
}

export interface MobileResponse<T = any> {
  request: MobileRequest;
  status: number;
  data: T;
}

export const defaultMobileInstance = MobileInstance.create({
  android: {
    package: config.VITE_ANDROID_PACKAGE,
  },
});
