import { Route } from 'react-router-dom';
import React from 'react';
import { createLazyRoute, createRoute } from '@core/router';
import { ProfilePredicate } from '@modules/profile/predicate';
import { Predicate } from 'effect';

const module = () => import('@modules/pro/previsions/ListPage');

const previsionsRoutes = (
  <Route path="previsions" {...createRoute({ restriction: Predicate.not(ProfilePredicate.isSoloPro) })}>
    <Route index {...createLazyRoute(module, m => m.default)} />
  </Route>
);

export default previsionsRoutes;
