import React, { FC, PropsWithChildren } from 'react';

import * as Sentry from '@sentry/react';
import { Http } from '@core/http';
import ErrorPage from '@shared/components/error-page/ErrorPage';

const SentryProvider: FC<PropsWithChildren> = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={<ErrorPage error={Http.Error.default} />}>{children}</Sentry.ErrorBoundary>;
};

export default SentryProvider;
