import { Profile } from '@modules/profile/model';
import { Predicate, ReadonlyArray } from 'effect';
import { Company } from '@modules/companies/model';

export namespace ProfilePredicate {
  export const isSoloPro: Predicate.Predicate<Profile> = profile => profile.subscription.isAccessLimited;
  export const isTeamPro: Predicate.Predicate<Profile> = Predicate.not(isSoloPro);

  export const hasCompanyKind =
    (...kinds: ReadonlyArray.NonEmptyArray<Company.Kind>): Predicate.Predicate<Profile> =>
    profile =>
      ReadonlyArray.contains(kinds, profile.company.kind);

  export const isUnepAffiliated: Predicate.Predicate<Profile> = Predicate.every([
    hasCompanyKind(Company.Kind.Landscaper),
    profile => profile.unep.isUnep && profile.unep.unepMemberId != null,
  ]);

  export const isFfgAffiliated: Predicate.Predicate<Profile> = Predicate.every([
    hasCompanyKind(Company.Kind.GreenKeeper),
    profile => profile.ffgolf.isFFGolf && profile.ffgolf.ffGolfMemberId != null,
  ]);

  export const hasRole =
    (...roles: ReadonlyArray.NonEmptyArray<Profile.Role>): Predicate.Predicate<Profile> =>
    profile =>
      ReadonlyArray.contains(roles, profile.role);

  export const hasRight =
    (right: keyof Profile.Rights): Predicate.Predicate<Profile> =>
    profile =>
      profile.rights[right];
}
