import { MobileService as CoreService } from '@core/mobile/service';
import { Mobile as CoreModel } from '@core/mobile/model';
import { Mobile as SharedModel } from '@shared/modules/mobile/model';
import MobileEffect = CoreModel.Effect;
import { Push } from '@shared/modules/push/model';

export namespace MobileService {
  import Handshake = SharedModel.Handshake;
  import Permission = SharedModel.Permission;

  export function getHandshake(): MobileEffect<Handshake> {
    return CoreService.get('handshake');
  }

  export function getPermissions(): MobileEffect<Array<Permission>> {
    return CoreService.get('permissions');
  }

  export function getFirebase(): MobileEffect<Push.PushToken> {
    return CoreService.get('firebase');
  }

  export function postPermission(type: Permission.Type): MobileEffect {
    return CoreService.post('permission', type);
  }
}
