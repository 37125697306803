import { MobileInstance } from '@core/mobile/config';

export class IosInstance {
  private constructor(private signal: AbortSignal) {}

  init(onPort: (port: MobileInstance.Port) => void) {
    const onMessage = () => {
      const port = window.webkit?.messageHandlers?.[IosInstance.EventType.Api];
      if (port) onPort(IosInstance.Port.create(port));
    };

    window.addEventListener(IosInstance.EventType.Init, onMessage, { signal: this.signal });
  }

  static create(signal: AbortSignal) {
    return new IosInstance(signal);
  }
}

export namespace IosInstance {
  export enum EventType {
    Init = 'ios-init',
    Api = 'ios-api',
  }

  export class Event extends CustomEvent<string> {
    constructor(type: EventType, detail: string, init?: EventInit) {
      super(type, { detail, ...init });
    }
  }

  export type EventMap = {
    [key in EventType]: Event;
  };

  export interface WebkitPort {
    postMessage: (message: any) => void;
  }

  export class Port implements MobileInstance.Port {
    private constructor(private port: WebkitPort) {}

    postMessage(message: any) {
      return this.port.postMessage(message);
    }

    listen(callback: (data: any) => void, signal: AbortSignal) {
      window.addEventListener(IosInstance.EventType.Api, e => callback(e.detail), { signal });
    }

    platform = MobileInstance.Platform.IOS;

    static create(port: WebkitPort) {
      return new Port(port);
    }
  }

  export const logStyle = 'background-color: blue; border: 2px solid green; color: white; font-size: 1.2em;';
}
