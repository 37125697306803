import { Route } from 'react-router-dom';
import { createLazyRoute, createRoute } from '@core/router';
import React from 'react';

import { Sensor } from '@modules/pro/sensors/model';

import { ProfilePredicate } from '@modules/profile/predicate';
import { Predicate } from 'effect';

const module = () => import('../module');

const pendingRoutes = (
  <Route path="pending" {...createRoute({ restriction: Predicate.not(ProfilePredicate.isSoloPro) })}>
    <Route index {...createLazyRoute(module, m => m.pendingList)} />
    <Route path={`${Sensor.Type.Sensoterra}/:id`} {...createLazyRoute(module, m => m.pendingSensoterraDetail)} />
    <Route path={`${Sensor.Type.Sinafis}/:id`} {...createLazyRoute(module, m => m.pendingSinafisDetail)} />
    <Route path={`${Sensor.Type.Hortee}/:id`} {...createLazyRoute(module, m => m.pendingHorteeDetail)} />
    <Route path="success/:id" {...createLazyRoute(module, m => m.pendingSuccessDetail)} />
  </Route>
);

export default pendingRoutes;
