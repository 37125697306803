import {
  createTheme,
  Drawer,
  Image,
  Input,
  InputWrapper,
  MantineProvider,
  Modal,
  NumberInput,
  Paper,
  PasswordInput,
  Radio,
  rem,
  Select,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { IconCalendar, IconCheck } from '@tabler/icons-react';
import 'dayjs/locale/fr';

import placeholderImage from '@assets/images/image-placeholder.svg';

import stepperClasses from './css/mantine/stepper.module.css';
import inputClasses from './css/mantine/input.module.css';

const theme = createTheme({
  fontFamily: 'Barlow, sans-serif',
  headings: {
    sizes: {
      h1: {
        fontSize: rem(20),
      },
    },
  },
  primaryColor: 'primary',
  primaryShade: 5,
  focusRing: 'never',
  colors: {
    red: ['#FFF5F5', '#FFE3E3', '#FFC9C9', '#FFA8A8', '#FF8787', '#FF6B6B', '#FA5252', '#E1523D', '#E03131', '#C92A2A'],
    blue: [
      '#E2DED8',
      '#D0EBFF',
      '#A5D8FF',
      '#74C0FC',
      '#4DABF7',
      '#7B6756',
      '#228BE6',
      '#1C7ED6',
      '#1971C2',
      '#483729',
    ],
    tertiary: [
      '#F5F4F2',
      '#F5F4F2',
      '#F5F4F2',
      '#E2DED8',
      '#A39382',
      '#7B6756',
      '#483729',
      '#363636',
      '#2E2E2E',
      '#000000',
    ],
    primary: [
      '#FCE1D2',
      '#FCE1D2',
      '#F7B690',
      '#EAC448',
      '#E8933F',
      '#E1523D',
      '#E1523D',
      '#E1523D',
      '#E1523D',
      '#E1523D',
    ],
    // 0 - 4 -> S100
    // 5 - 9 -> S500
    secondary: [
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#43B02A',
      '#43B02A',
      '#43B02A',
      '#43B02A',
      '#43B02A',
    ],
    complementary: [
      '#FFFFFF',
      '#FCF6E4',
      '#FDF4EC',
      '#FAE9D9',
      '#FDEEEC',
      // No used
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
    ],
    background: ['#f5f4f2', '#f5f4f2', '#9b9b9b', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
  },
  components: {
    Tooltip: Tooltip.extend({
      defaultProps: {
        events: { touch: true, hover: true, focus: false },
      },
    }),
    Select: Select.extend({
      defaultProps: {
        allowDeselect: false,
        withCheckIcon: false,
        size: 'md',
        radius: 8,
      },
      classNames: {
        root: inputClasses.root,
        input: inputClasses.input,
        label: inputClasses.label,
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      classNames: {
        root: inputClasses.root,
        input: inputClasses.input,
        label: inputClasses.label,
      },
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      classNames: {
        root: inputClasses.root,
        input: inputClasses.input,
        label: inputClasses.label,
      },
    }),
    Input: Input.extend({
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      classNames: {
        input: inputClasses.input,
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      classNames: {
        root: inputClasses.root,
        input: inputClasses.input,
        label: inputClasses.label,
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        size: 'md',
        radius: 8,
      },
      classNames: {
        input: inputClasses.input,
      },
    }),
    Text: Text.extend({
      defaultProps: {
        color: 'inherit',
      },
    }),
    InputWrapper: InputWrapper.extend({
      styles: {
        label: {
          fontWeight: 600,
          paddingBottom: 5,
        },
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    }),
    NavLink: Paper.extend({
      defaultProps: {
        // typed by @shared/Link
        component: NavLink,
      } as any,
    }),
    Radio: Radio.extend({
      defaultProps: {
        color: 'primary.5',
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        overlayProps: {
          color: 'gray.2',
          blur: 2,
        },
      },
      styles: {
        root: {
          position: 'relative',
          zIndex: 20000,
        },
      },
    }),
    Drawer: Drawer.extend({
      styles: {
        root: {
          position: 'relative',
          zIndex: 20000,
        },
      },
    }),
    Stepper: Stepper.extend({
      defaultProps: {
        color: '#ce5b46',
        completedIcon: <IconCheck strokeWidth={3} />,
        allowNextStepsSelect: false,
      },
      classNames: {
        content: stepperClasses.content,
        separator: stepperClasses.separator,
        stepIcon: stepperClasses.stepIcon,
      },
    }),
    DatePickerInput: {
      defaultProps: {
        locale: 'fr',
        valueFormat: 'DD/MM/YYYY',
        icon: <IconCalendar />,
      },
    },
    Image: Image.extend({
      defaultProps: {
        fallbackSrc: placeholderImage,
      },
    }),
  },
});

const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme}>
      <Notifications position="top-right" zIndex={10000000} />
      {children}
    </MantineProvider>
  );
};

export default MantineTheme;
