import { Action, EmptyAction, FormDataAction, PayloadAction } from './index';
import { useFetcher } from 'react-router-dom';
import { useCallback, useRef } from 'react';
import { z } from 'zod';
import { Cause, Effect, Exit, Option, pipe } from 'effect';

export function useAction<
  Action extends PayloadAction<any, any, any, any>,
  PayloadSchema = Action extends PayloadAction<infer Payload, any, any, any> ? Payload : never,
  Payload = PayloadSchema extends z.ZodType ? z.infer<PayloadSchema> : never,
  R = Action extends PayloadAction<any, any, infer R, any> ? R : never,
  E = Action extends PayloadAction<any, any, any, infer E> ? E : never,
>(
  action: Action,
  actionUrl?: string,
): [boolean, (payload: Payload) => Effect.Effect<never, E, R>, Option.Option<E>, boolean];

export function useAction<
  Action extends FormDataAction<any, any, any>,
  R = Action extends FormDataAction<any, infer R, any> ? R : never,
  E = Action extends FormDataAction<any, any, infer E> ? E : never,
>(
  action: Action,
  actionUrl?: string,
): [boolean, (formData: FormData) => Effect.Effect<never, E, R>, Option.Option<E>, boolean];

export function useAction<
  Action extends EmptyAction<any, any, any>,
  R = Action extends EmptyAction<any, infer R, any> ? R : never,
  E = Action extends EmptyAction<any, any, infer E> ? E : never,
>(action: Action, actionUrl?: string): [boolean, () => Effect.Effect<never, E, R>, Option.Option<E>, boolean];

/**
 * Hooks permettant d'appeler une action
 *
 * @param action - action que l'on veut appeller
 * @param actionUrl - url de l'action, à utiliser si l'action se trouve sur une autre route
 */
export function useAction<R, E>(
  action: Action,
  actionUrl?: string,
): [boolean, (payload: unknown) => Effect.Effect<never, E, R>, Option.Option<E>, boolean] {
  const fetcher = useFetcher<Exit.Exit<E, R>>();

  const fetcherRef = useRef(fetcher);

  fetcherRef.current = fetcher;

  const loading = fetcher.state !== 'idle';

  const error = pipe(
    Option.fromNullable(fetcher.data),
    Option.flatMap(Exit.causeOption),
    Option.flatMap(Cause.failureOption),
  );

  const success = Option.exists(Option.fromNullable(fetcher.data), Exit.isSuccess);

  const handleAction = useCallback(
    (payload: unknown) => {
      return pipe(
        createActionFormData(action, payload),
        Effect.flatMap(formData =>
          Effect.sync(() => {
            fetcherRef.current.submit(formData, { method: 'post', action: actionUrl });
          }),
        ),
        Effect.flatMap(() =>
          Effect.promise(() => {
            return new Promise<Exit.Exit<E, R>>((resolve, reject) => {
              let interval: NodeJS.Timeout;

              let hasRun = false;

              /**
               * Check toutes les 5 secondes si l'état du fetcher change
               */
              const waitForResult = () => {
                if (!hasRun) {
                  hasRun = fetcherRef.current.state !== 'idle';
                } else if (fetcherRef.current.state === 'idle') {
                  const res = fetcherRef.current.data;

                  if (res) {
                    resolve(res);
                  } else {
                    reject(new Error('No data return from action'));
                  }

                  clearInterval(interval);
                }
              };

              interval = setInterval(waitForResult, 5);

              waitForResult();
            });
          }),
        ),
        Effect.flatMap(
          Exit.match({
            onSuccess: Effect.succeed,
            onFailure: cause =>
              Option.match(Cause.failureOption(cause), {
                onSome: Effect.fail,
                onNone: () => Effect.dieMessage('No failure in cause'),
              }),
          }),
        ),
      );
    },
    [action, actionUrl],
  );

  return [loading, handleAction, error, success];
}

/**
 * Créé le FormData suivant de type de l'action
 *
 * @param action
 * @param payload
 */
function createActionFormData(action: Action, payload?: unknown) {
  if ('formData' in action) {
    if (payload instanceof FormData) {
      payload.set('_type', action.type);

      return Effect.succeed(payload);
    }

    return Effect.dieMessage('No FormData argument for FormData action');
  }

  if ('payload' in action) {
    if (payload) {
      const formData = new FormData();

      formData.set('_type', action.type);
      formData.set('payload', JSON.stringify(payload));

      return Effect.succeed(formData);
    }

    return Effect.dieMessage('No payload argument for payload action');
  }

  const formData = new FormData();

  formData.set('_type', action.type);

  return Effect.succeed(formData);
}
