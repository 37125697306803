import { Sensor } from '@modules/pro/sensors/model';
import { LocalDateTime } from '@shared/modules/dates';
import { Utils } from '@shared/utils/model';
import {
  Icon,
  IconBattery1,
  IconBattery2,
  IconBattery3,
  IconBattery4,
  IconBatteryOff,
  IconWifi,
  IconWifi0,
  IconWifi1,
  IconWifi2,
} from '@tabler/icons-react';
import { MantineColor } from '@mantine/core';
import { OrderUtils } from '@shared/utils/order';
import { Equivalence, Order } from 'effect';

export namespace Measure {
  export enum SignalStrength {
    Poor = 'poor',
    Fair = 'fair',
    Good = 'good',
    Excellent = 'excellent',
  }

  export const signalStrengthLabel: Record<SignalStrength, string> = {
    [SignalStrength.Poor]: 'Faible',
    [SignalStrength.Fair]: 'Correct',
    [SignalStrength.Good]: 'Bon',
    [SignalStrength.Excellent]: 'Excellent',
  };

  export const signalStrengthIcon: Record<SignalStrength, Icon> = {
    [SignalStrength.Poor]: IconWifi0,
    [SignalStrength.Fair]: IconWifi1,
    [SignalStrength.Good]: IconWifi2,
    [SignalStrength.Excellent]: IconWifi,
  };

  const signalStrengthOrdering: Record<SignalStrength, number> = {
    [SignalStrength.Poor]: 0,
    [SignalStrength.Fair]: 1,
    [SignalStrength.Good]: 2,
    [SignalStrength.Excellent]: 3,
  };

  const signalStrengthOrd = OrderUtils.ordFromOrdering(signalStrengthOrdering);

  export enum Type {
    Temperature = 'temperature',
    Humidity = 'humidity',
    Battery = 'battery',
    Nutrition = 'nutrition',
    Signal = 'signal',
  }

  export const typeEq: Equivalence.Equivalence<Type> = Equivalence.string;

  export const typeTitle: Record<Type, string> = {
    [Type.Temperature]: 'Température',
    [Type.Humidity]: 'Humidité',
    [Type.Battery]: 'Batterie',
    [Type.Nutrition]: 'Conductivité',
    [Type.Signal]: 'Signal',
  };

  export const typeOrd: { [key in Type]: Order.Order<Value<key>> } = {
    [Type.Temperature]: Utils.temperatureOrd,
    [Type.Humidity]: Utils.percentOrd,
    [Type.Battery]: Utils.percentOrd,
    [Type.Nutrition]: Order.number,
    [Type.Signal]: signalStrengthOrd,
  };

  export type Value<Type extends Measure.Type = Measure.Type> = {
    [Type.Temperature]: Utils.Temperature;
    [Type.Humidity]: Utils.Percent;
    [Type.Battery]: Utils.Percent;
    [Type.Nutrition]: number;
    [Type.Signal]: SignalStrength;
  }[Type];

  export enum BatteryLevel {
    Empty = 'empty',
    Poor = 'poor',
    Fair = 'fair',
    Good = 'good',
    Excellent = 'excellent',
  }

  export const batteryBreakpoint: Record<BatteryLevel, Utils.Percent> = {
    [BatteryLevel.Empty]: Utils.Percent.parse(0.01),
    [BatteryLevel.Poor]: Utils.Percent.parse(0.25),
    [BatteryLevel.Fair]: Utils.Percent.parse(0.5),
    [BatteryLevel.Good]: Utils.Percent.parse(0.75),
    [BatteryLevel.Excellent]: Utils.Percent.parse(1),
  };

  export const batteryIcon: Record<BatteryLevel, Icon> = {
    [BatteryLevel.Empty]: IconBatteryOff,
    [BatteryLevel.Poor]: IconBattery1,
    [BatteryLevel.Fair]: IconBattery2,
    [BatteryLevel.Good]: IconBattery3,
    [BatteryLevel.Excellent]: IconBattery4,
  };

  export const batteryLabel: Record<BatteryLevel, string> = {
    [BatteryLevel.Empty]: 'Vide',
    [BatteryLevel.Poor]: 'Faible',
    [BatteryLevel.Fair]: 'Correct',
    [BatteryLevel.Good]: 'Bon',
    [BatteryLevel.Excellent]: 'Excellent',
  };

  export namespace RealTimeMeasure {
    export interface Impl<Type extends Measure.Type> {
      at: LocalDateTime;
      value: Value<Type>;
      type: Type;
    }
  }

  export type RealTimeMeasure =
    | RealTimeMeasure.Impl<Type.Humidity>
    | RealTimeMeasure.Impl<Type.Battery>
    | RealTimeMeasure.Impl<Type.Signal>
    | RealTimeMeasure.Impl<Type.Temperature>;

  export namespace Last {
    export type Impl<Type extends Sensor.Type> = Record<Sensor.Probe<Type>, Array<RealTimeMeasure>>;
  }

  export type Last = Last.Impl<Sensor.Type.Sinafis> | Last.Impl<Sensor.Type.Hortee> | Last.Impl<Sensor.Type.Sensoterra>;

  export namespace History {
    export interface Item<Value> {
      at: LocalDateTime;
      value: Value;
    }

    export interface Filter {
      startDate: LocalDateTime;
      endDate: LocalDateTime;
      unit: Utils.ChronoUnit;
    }

    export const Type = {
      Battery: Measure.Type.Battery,
      Temperature: Measure.Type.Temperature,
      Humidity: Measure.Type.Humidity,
      Nutrition: Measure.Type.Nutrition,
    } satisfies Partial<{ [key in keyof typeof Measure.Type]: (typeof Measure.Type)[key] }>;

    export type Type = (typeof Type)[keyof typeof Type];

    export const typeColor: Record<Type, MantineColor> = {
      [Type.Battery]: '#7950F2',
      [Type.Temperature]: '#F06595',
      [Type.Humidity]: '#22B8CF',
      [Type.Nutrition]: '#E8933F',
    };

    export interface Measure<Type extends History.Type = History.Type> {
      type: Type;
      values: Array<Item<Measure.Value<Type>>>;
    }
  }

  export type History<Type extends Sensor.Type = Sensor.Type> = Record<Sensor.Probe<Type>, Array<History.Measure>>;
}
