import React, { FC } from 'react';
import { defineRoute, redirect } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { Alert, Box, Button, Divider, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { RenderUtils } from '@shared/utils/render';
import { OAuthUtils } from '@core/oauth/utils';
import { Effect, Function } from 'effect';
import { QueryUtils } from '@shared/utils/queries';
import logo from '@assets/logos/logo-inversed.svg';
import { Link } from 'react-router-dom';

import classes from './LoginPage.module.css';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import { z } from 'zod';

const queries = z.object({
  referrer: z.string().catch(() => '/'),
});

const actions = {
  authenticate: defineAction({
    type: 'authenticate',
    payload: Auth.AuthenticateParams,
    handler: ({ request, payload }) =>
      Effect.gen(function* (_) {
        yield* _(AuthService.authenticate(payload));

        const { referrer } = yield* _(QueryUtils.parseFromRequest(request, queries));

        yield* _(redirect(referrer));
      }),
    flashOptions: {
      error: Function.constFalse,
    },
  }),
};

const LoginPage: FC = () => {
  const [loading, authenticate, error] = useAction(actions.authenticate);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Auth.AuthenticateParams>({
    resolver: zodResolver(Auth.AuthenticateParams),
  });

  const onSubmit = (params: Auth.AuthenticateParams) => Effect.runPromise(authenticate(params));

  return (
    <Box p={30} w="100%" className={classes.container}>
      <Group justify="center" pb={30}>
        <img src={logo} width={166} height={104} alt="Logo" />
      </Group>

      {RenderUtils.renderOptional(error, {
        onSome: error =>
          error._tag !== 'SchemaError' ? (
            <Alert color="red" mb={20}>
              <Text c="red" fz="sm" fw={600} ta="center">
                {OAuthUtils.getOAuthErrorMessage(error)}
              </Text>
            </Alert>
          ) : null,
      })}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          type="email"
          label="Adresse e-mail"
          placeholder="Addresse e-mail"
          variant="filled"
          withAsterisk
          {...register('email')}
          error={!!errors.email}
        />

        <PasswordInput
          label="Mot de passe"
          placeholder="Mot de passe"
          variant="filled"
          withAsterisk
          pt={15}
          {...register('password')}
          error={!!errors.password}
        />

        <Group justify="end">
          <Text component={Link} to="/password-reset" ta="right" c="primary.5" fz={12} td="underline">
            Mot de passe oublié ?
          </Text>
        </Group>

        <Group justify="center" mt={30}>
          <Button type="submit" loading={loading} tt="uppercase">
            Se connecter
          </Button>
        </Group>

        <Group w="100%">
          <Divider
            orientation="horizontal"
            c="white"
            label="ou"
            tt="uppercase"
            labelPosition="center"
            className={classes.divider}
            mt={40}
            mb={30}
          />

          <Text c="white" ta="center">
            Vous êtes un paysagiste, un intendant de terrain de sport, ou une collectivité, rejoignez notre communauté
            !!
          </Text>

          <Button component={Link} to="/sign-up" variant="outline" className={classes.button}>
            Créer un compte
          </Button>
        </Group>
      </form>
    </Box>
  );
};

const loginRoute = defineRoute({
  element: <LoginPage />,
  actions,
});

export default loginRoute;
