import { Http, HttpService } from '@core/http';
import { Profile } from '@modules/profile/model';
import config from '@root/config';

export namespace ProfileService {
  const URI = `${config.VITE_API_PREFIX}/profile`;
  const URI_ACCOUNT = `${config.VITE_API_PREFIX}/account`;

  export function getProfile(): Http.Effect<Profile> {
    return HttpService.get(URI);
  }

  export function updateAccount(params: Profile.Params): Http.Effect {
    return HttpService.post(URI_ACCOUNT, params);
  }

  export function deleteAccount(): Http.Effect {
    return HttpService.delete(URI_ACCOUNT);
  }
}
