import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  ScrollRestoration,
  useNavigation,
} from 'react-router-dom';

import { createRoute } from '@core/router';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';

import authRoutes from '@modules/auth/routes';
import activationRoutes from '@modules/activation/routes';
import profileLoaderRoute from '@modules/profile/loader';
import coachRoutes from '@modules/coach/routes';
import signUpRoutes from '@modules/sign-up/routes';
import profileRoutes from '@modules/profile/routes';
import homeRoutes from '@modules/home/routes';
import productsRoutes from '@modules/products/routes';
import homeProRoutes from '@modules/pro/home/routes';
import sensorsRoutes from '@modules/pro/sensors/routes';
import diseasesRoutes from '@modules/pro/diseases/routes';
import emergencyNumbersRoutes from '@modules/pro/emergency-numbers/routes';
import previsionsRoutes from '@modules/pro/previsions/routes';
import newsRoutes from '@modules/news/routes';
import notificationsRoutes from '@modules/notifications/routes';
import surveysRoutes from '@modules/surveys/routes';
import unepRoutes from '@modules/unep/routes';
import catalogsRoutes from '@modules/catalogs/routes';
import partnersRoutes from '@modules/partners/routes';
import usersRoutes from '@modules/users/routes';
import { passwordResetRoutes } from '@modules/password-reset/routes';
import subscriptionsRoutes from '@modules/subscriptions/routes';

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      {authRoutes}
      {signUpRoutes}
      {activationRoutes}
      {passwordResetRoutes}

      <Route {...createRoute(profileLoaderRoute)}>
        {profileRoutes}

        {/* ---------- Platform ---------- */}
        {homeRoutes}
        {catalogsRoutes}
        {productsRoutes}
        {coachRoutes}
        {newsRoutes}
        {notificationsRoutes}
        {surveysRoutes}
        {unepRoutes}
        {partnersRoutes}
        {usersRoutes}
        {subscriptionsRoutes}

        {/* ---------- Platform Pro ---------- */}
        {homeProRoutes}
        {sensorsRoutes}
        {diseasesRoutes}
        {emergencyNumbersRoutes}
        {previsionsRoutes}

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>,
  ),
);

export default router;
