import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./ListPage');

export const notificationsRoutes = (
  <Route path="notifications">
    <Route index {...createLazyRoute(module, m => m.default)} />
  </Route>
);

export default notificationsRoutes;
