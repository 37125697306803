import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

export const unepRoutes = (
  <Route path="unep">
    <Route path="report" {...createLazyRoute(module, m => m.unepReportContextRoute)}>
      <Route index {...createLazyRoute(module, m => m.unepReportContextStepRoute)} />
      <Route path="target" {...createLazyRoute(module, m => m.unepReportTargetStepRoute)} />
      <Route path="pest" {...createLazyRoute(module, m => m.unepReportPestStepRoute)} />
      <Route path="location" {...createLazyRoute(module, m => m.unepReportLocationStepRoute)} />
    </Route>

    <Route path="report/success" {...createLazyRoute(module, m => m.unepReportSuccessRoute)} />
  </Route>
);

export default unepRoutes;
