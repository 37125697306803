import { MobileInstance } from '@core/mobile/config';

export namespace Mobile {
  export namespace Handshake {
    export interface Android {
      platform: MobileInstance.Platform.Android;
      manufacturer: string;
      brand: string;
      model: string;
      isMiui: boolean;
    }

    export interface IOS {
      platform: MobileInstance.Platform.IOS;
    }
  }
  export type Handshake = Handshake.Android | Handshake.IOS;

  export namespace Permission {
    export enum Type {
      Location = 'location',
      Notification = 'notification',
      MiuiBackgroundStartActivity = 'miuiBackgroundStartActivity',
    }
  }

  export interface Permission {
    name: Permission.Type;
    isGranted: boolean;
  }
}
