import { defineLoader } from '@core/router/loader';
import { z } from 'zod';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import { defineRoute, redirect } from '@core/router';
import { QueryUtils } from '@shared/utils/queries';
import { Effect } from 'effect';

const queries = z.object({
  referer: z.string().catch(() => '/'),
});

const params = z.object({ token: Auth.Token });

const loader = defineLoader({
  params,
  handler: ({ params, request }) =>
    Effect.gen(function* (_) {
      yield* _(AuthService.exchangeToken(params.token));

      const { referer } = yield* _(QueryUtils.parseFromRequest(request, queries));

      yield* _(redirect(referer));
    }),
});

const loginWithTokenRoute = defineRoute({
  loader,
});

export default loginWithTokenRoute;
