import z from 'zod';
import { Threshold } from '@modules/iot/model';
import HorteeSensorIcon from '@assets/icons/hortee-sensor.svg?react';
import SensoterraSensorIcon from '@assets/icons/sensoterra-sensor.svg?react';
import SinafisSensorIcon from '@assets/icons/sinafis-sensor.svg?react';
import { FC } from 'react';
import { Zone } from '@modules/iot/zones/model';
import { Measure } from '@modules/measures/model';
import { Utils } from '@shared/utils/model';
import { LocalDateTime } from '@shared/modules/dates';
import { Sensoterra } from '@modules/pro/sensors/sensoterra/model';
import { Sinafis } from '@modules/pro/sensors/sinafis/model';
import { Hortee } from '@modules/pro/sensors/hortee/model';
import sinafisLogo from '@assets/logos/sinafis.svg';
import horteeLogo from '@assets/logos/hortee.svg';
import sensoterraLogo from '@assets/logos/sensoterra.svg';
import { MantineColor } from '@mantine/core';
import { Geo } from '@shared/modules/geo/model';
import { SensorFilter } from '@modules/pro/sensors/map/filters/model';
import { Equivalence, Function } from 'effect';
import { NonEmptyString } from '@shared/schemas';

export namespace Sensor {
  export const Id = z.string().uuid().brand('SensorId');
  export type Id = z.infer<typeof Id>;
  export const idEq: Equivalence.Equivalence<Id> = Equivalence.string;
  export const Serial = z.string().uuid().brand('SensorSerial');
  export type Serial = z.infer<typeof Serial>;

  export enum Type {
    Sensoterra = 'sensoterra',
    Hortee = 'hortee',
    Sinafis = 'sinafis',
  }

  export const typeIcon: Record<Type, FC<React.ComponentProps<'svg'> & { title?: string }>> = {
    [Type.Sensoterra]: SensoterraSensorIcon,
    [Type.Hortee]: HorteeSensorIcon,
    [Type.Sinafis]: SinafisSensorIcon,
  };

  export const typeTitle: Record<Type, string> = {
    [Type.Sensoterra]: 'Sensoterra',
    [Type.Hortee]: 'Hortee',
    [Type.Sinafis]: 'Sinafis',
  };

  export const typeLogo: Record<Type, string> = {
    [Type.Sensoterra]: sensoterraLogo,
    [Type.Hortee]: horteeLogo,
    [Type.Sinafis]: sinafisLogo,
  };

  export const typeEq: Equivalence.Equivalence<Type> = Equivalence.string;

  export namespace Probe {
    export const Identifier = {
      ...Sensoterra.Probe.Identifier,
      ...Hortee.Probe.Identifier,
      ...Sinafis.Probe.Identifier,
    };

    export type Identifier = (typeof Identifier)[keyof typeof Identifier];

    export const identifierLabel: Record<Identifier, string> = {
      [Identifier.Ground]: 'Sol',
      [Identifier.Ground1]: 'Sol 1',
      [Identifier.Ground2]: 'Sol 2',
      [Identifier.Transmitter]: 'Transmetteur',
      [Identifier.Leaf]: 'Foliaire',
    };

    export const identifierColor: Record<Identifier, MantineColor> = {
      [Identifier.Ground]: '#afafaf',
      [Identifier.Ground1]: '#afafaf',
      [Identifier.Ground2]: '#afafaf',
      [Identifier.Transmitter]: '#333333',
      [Identifier.Leaf]: '#ffffff',
    };
  }

  export type Probe<Type extends Sensor.Type = Sensor.Type> = {
    [Sensor.Type.Sensoterra]: Sensoterra.Probe.Identifier;
    [Sensor.Type.Hortee]: Hortee.Probe.Identifier;
    [Sensor.Type.Sinafis]: Sinafis.Probe.Identifier;
  }[Type];
}

export interface Sensor<Type extends Sensor.Type = Sensor.Type> {
  id: Sensor.Id;
  type: Type;
  name: string;
}

export namespace ActiveSensor {
  import INSEECode = Geo.City.INSEECode;
  import PostalCode = Utils.PostalCode;

  const Filter = z.object({
    search: NonEmptyString.nullable().catch(Function.constNull),
    zoneId: Zone.Id.nullable().catch(Function.constNull),
    sensorType: z.nativeEnum(Sensor.Type).nullable().catch(Function.constNull),
    sinafisProbeIdentifier: z.nativeEnum(Sinafis.Probe.Identifier).nullable().catch(Function.constNull),
    alertLevel: z.nativeEnum(Threshold.Level).nullable().catch(Function.constNull),
    alertType: z.nativeEnum(Measure.Type).nullable().catch(Function.constNull),
    code: INSEECode.nullable().catch(Function.constNull),
    postalCode: PostalCode.nullable().catch(Function.constNull),
    filterId: SensorFilter.Id.nullish().catch(Function.constNull),
  });

  export type Filter = z.infer<typeof Filter>;

  export const FilterWithCity = Filter.extend({
    city: NonEmptyString.nullable().catch(Function.constNull),
  });

  export type FilterWithCity = z.infer<typeof FilterWithCity>;

  export type Config<Type extends Sensor.Type> = {
    [Sensor.Type.Sensoterra]: Sensoterra.Config;
    [Sensor.Type.Hortee]: Hortee.Config;
    [Sensor.Type.Sinafis]: Sinafis.Config;
  }[Type];

  export interface Alert<Type extends Sensor.Type> {
    probe: Sensor.Probe<Type>;
    type: Measure.Type;
    level: Threshold.Level;
    since: LocalDateTime;
  }

  export const defaultFilter: Filter = {
    search: null,
    zoneId: null,
    sensorType: null,
    sinafisProbeIdentifier: null,
    alertLevel: null,
    alertType: null,
    postalCode: null,
    code: null,
    filterId: null,
  };

  export interface List {
    zones: Array<Zone>;
    sensors: Array<ActiveSensor>;
  }

  export interface Impl<Type extends Sensor.Type> extends Sensor<Type> {
    serial: Sensor.Serial;
    zone: Zone | null;
    location: Utils.GPSCoordinates;
    config: ActiveSensor.Config<Type>;
    measures: Measure.Last.Impl<Type> | null;
    alerts: Array<ActiveSensor.Alert<Type>>;
    comment: string | null;
  }
}

export type ActiveSensor =
  | ActiveSensor.Impl<Sensor.Type.Hortee>
  | ActiveSensor.Impl<Sensor.Type.Sinafis>
  | ActiveSensor.Impl<Sensor.Type.Sensoterra>;
