import React, { FC } from 'react';
import router from './routes';
import { HelmetProvider } from 'react-helmet-async';
import MantineTheme from '@styles/mantine';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { NavigationProgress } from '@mantine/nprogress';
import { RouterProvider } from 'react-router-dom';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';
import { Center, Loader } from '@mantine/core';
import Layout from '@layout/Layout';
import MobileDownIndicator from '@core/mobile/components/MobileDownIndicator';

const App: FC = () => {
  return (
    <SentryProvider>
      <MantineTheme>
        <ApiDownIndicator />
        <MobileDownIndicator />

        <NavigationProgress color="primary" size={2} />

        <Layout>
          <HelmetProvider>
            <RouterProvider
              router={router}
              fallbackElement={
                <Center w="100%">
                  <Loader size="xl" color="primary.5" variant="bars" />
                </Center>
              }
            />
          </HelmetProvider>
        </Layout>
      </MantineTheme>
    </SentryProvider>
  );
};

export default App;
