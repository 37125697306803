import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';
import React from 'react';

import alertsRoutes from '@modules/alert-triggers/routes';
import pendingRoutes from '@modules/pro/sensors/pending/routes';

const module = () => import('./module');

const sensorsRoutes = (
  <Route path="sensors">
    <Route index {...createLazyRoute(module, m => m.sensorsDashboardRoute)} />
    <Route path="map" {...createLazyRoute(module, m => m.sensorsMap)}>
      <Route path=":id" {...createLazyRoute(module, m => m.mapDetailRoute)} />
    </Route>
    {pendingRoutes}
    {alertsRoutes}
  </Route>
);

export default sensorsRoutes;
