import React from 'react';
import { Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';

const module = () => import('./module');

const usersRoutes = (
  <Route path="users">
    <Route index {...createLazyRoute(module, m => m.usersListRoute)} />
    <Route path="new" {...createLazyRoute(module, m => m.createUserRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.userDetailRoute)} />
  </Route>
);

export default usersRoutes;
