import { Navigate, Route } from 'react-router-dom';
import { createLazyRoute } from '@core/router';
import { ReportFlowStepProps } from '@modules/pro/diseases/alert-reports/flow';

const module = () => import('./module');

const CREATE_PROPS: ReportFlowStepProps = { type: 'create' };
const EDIT_PROPS: ReportFlowStepProps = { type: 'edit' };

const alertReportsRoutes = (
  <Route path="alert-reports">
    <Route index element={<Navigate to="map" relative="path" replace />} />

    <Route path="map" {...createLazyRoute(module, m => m.alertReportsMapRoute)} />
    <Route path="list" {...createLazyRoute(module, m => m.alertReportsListRoute)} />

    <Route path="new" {...createLazyRoute(module, m => m.createReportContextRoute)}>
      <Route index {...createLazyRoute(module, m => m.reportCategoriesStepRoute, undefined, CREATE_PROPS)} />
      <Route
        path="sub-categories"
        {...createLazyRoute(module, m => m.reportSubCategoriesStepRoute, undefined, CREATE_PROPS)}
      />
      <Route path="types" {...createLazyRoute(module, m => m.reportTypesStepRoute, undefined, CREATE_PROPS)} />
      <Route path="form" {...createLazyRoute(module, m => m.reportFormStepRoute, undefined, CREATE_PROPS)} />
      <Route path="images" {...createLazyRoute(module, m => m.reportImagesStepRoute, undefined, CREATE_PROPS)} />
      <Route path="location" {...createLazyRoute(module, m => m.reportLocationStepRoute, undefined, CREATE_PROPS)} />
    </Route>

    <Route path="new/success" {...createLazyRoute(module, m => m.createReportSuccessRoute)} />

    <Route path=":id" {...createLazyRoute(module, m => m.alertReportDetailRoute)} />
    <Route path=":id/analysis" {...createLazyRoute(module, m => m.alertReportAnalysisRoute)} />

    <Route path=":id/edit" {...createLazyRoute(module, m => m.editAlertReportContextRoute)}>
      <Route index {...createLazyRoute(module, m => m.reportCategoriesStepRoute, undefined, EDIT_PROPS)} />
      <Route
        path="sub-categories"
        {...createLazyRoute(module, m => m.reportSubCategoriesStepRoute, undefined, EDIT_PROPS)}
      />
      <Route path="types" {...createLazyRoute(module, m => m.reportTypesStepRoute, undefined, EDIT_PROPS)} />
      <Route path="form" {...createLazyRoute(module, m => m.reportFormStepRoute, undefined, EDIT_PROPS)} />
      <Route path="images" {...createLazyRoute(module, m => m.reportImagesStepRoute, undefined, EDIT_PROPS)} />
      <Route path="location" {...createLazyRoute(module, m => m.reportLocationStepRoute, undefined, EDIT_PROPS)} />
    </Route>
  </Route>
);

export default alertReportsRoutes;
