import React, { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Activation } from '@modules/activation/model';
import { useForm } from 'react-hook-form';
import { Button, PasswordInput } from '@mantine/core';

import classes from './ActivationForm.module.css';

interface ActivationFormProps {
  onSubmit: (body: Activation.Form) => void;
  loading: boolean;
}

const ActivationForm: FC<ActivationFormProps> = ({ onSubmit, loading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Activation.Form>({
    resolver: zodResolver(Activation.Form),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className={classes.container}>
      <PasswordInput
        {...register('password')}
        label="Mot de passe"
        placeholder="Mot de passe"
        variant="filled"
        error={!!errors.password}
        withAsterisk
      />

      <PasswordInput
        {...register('confirmPassword')}
        label="Confirmation du mot de passe"
        placeholder="Confirmation du mot de passe"
        variant="filled"
        error={
          !!errors.confirmPassword
            ? errors.confirmPassword.type === 'custom'
              ? errors.confirmPassword.message
              : true
            : null
        }
        withAsterisk
        pt={15}
      />

      <Button type="submit" fullWidth className={classes.button} loading={loading}>
        Activer mon compte
      </Button>
    </form>
  );
};

export default ActivationForm;
