import { createLazyRoute } from '@core/router';
import { Route } from 'react-router-dom';

const module = () => import('./module');

const productsRoutes = (
  <Route path="products">
    <Route index {...createLazyRoute(module, m => m.productsListRoute)} />
    <Route path="favorites" {...createLazyRoute(module, m => m.favoritesListRoute)} />
    <Route path=":id" {...createLazyRoute(module, m => m.productDetailRoute)} />
  </Route>
);

export default productsRoutes;
