import z from 'zod';
import { LoaderFunctionArgs, defer as reactRouterDefer } from 'react-router-dom';
import { DeferData, Loader } from './index';
import { Http } from '@core/http';
import { parseParams } from '@core/router';
import { Effect, Option, pipe, ReadonlyRecord } from 'effect';

export function defineLoader<
  ParamsSchema extends z.ZodType = z.ZodType<unknown>,
  R = unknown,
  ID extends string = never,
>(loader: Loader<ParamsSchema, R, ID>): Loader<ParamsSchema, R, ID> {
  return loader;
}

export function loaderHandler<ParamsSchema extends z.ZodType = z.ZodType<unknown>, R = unknown>(
  loader?: Loader<ParamsSchema, R>,
): (args: LoaderFunctionArgs) => Promise<R> {
  return args => {
    const program = pipe(
      Option.fromNullable(loader),
      Option.match({
        onNone: () => Effect.succeed({} as R),
        onSome: loader =>
          pipe(
            parseParams(args.params, loader.params),
            Effect.flatMap(params => loader.handler({ request: args.request, params })),
          ),
      }),
    );

    return pipe(
      program,
      Effect.map(data => {
        if (isDeferData(data)) {
          return reactRouterDefer(
            pipe(
              data.data,
              ReadonlyRecord.map(value => (Effect.isEffect(value) ? Effect.runPromise(value as any) : value)),
            ),
          ) as R;
        }

        return data;
      }),
      Effect.orDie,
      Effect.catchSomeDefect(defect => {
        if (defect instanceof Response) {
          return Option.some(Effect.succeed(defect as R));
        }

        if (defect instanceof Http.Error) {
          return Option.some(Effect.succeed(defect.toResponse() as R));
        }

        return Option.none();
      }),
      Effect.runPromise,
    ).then(res => {
      if (res instanceof Response) {
        throw res;
      }

      return res;
    });
  };
}

export function defer<R extends Record<string, unknown>>(data: R): DeferData<R> {
  return {
    _tag: 'DeferData',
    data,
  };
}

export function isDeferData(value: unknown): value is DeferData<any> {
  return (value as any)._tag === 'DeferData';
}
