import { defaultAxiosInstance } from '@core/http/config';
import { Http } from '@core/http/model';
import { Effect, pipe, Schedule } from 'effect';
import { Filter } from '@shared/modules/filter';
import { Range } from '@shared/modules/range';
import { StringUtils } from '@shared/utils/string';
import { AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios';
import { hideApiDownIndicator, showApiDownIndicator } from '@core/http/components/ApiDownIndicator';
import { OAuthService } from '@core/oauth/service';

function sendRequest<R, E>(
  request: (signal: AbortSignal) => Promise<AxiosResponse<R>>,
  raw?: true,
): Http.Effect<R | AxiosResponse<R>, E> {
  const onError = (err: unknown): Http.Error<E> => Http.Error.fromAxiosError(err as any);

  const retryPolicy = pipe(
    Schedule.union(Schedule.exponential('500 millis'), Schedule.spaced('2 seconds')),
    Schedule.whileInput<Http.Error>(err => err.isDownError()),
    Schedule.tapOutput(([, occur]) => {
      if (occur === 1) {
        return showApiDownIndicator;
      } else {
        return Effect.unit;
      }
    }),
  );

  return pipe(
    Effect.tryPromise({
      try: request,
      catch: onError,
    }),
    Effect.retry(retryPolicy),
    Effect.matchEffect({
      onSuccess: res => Effect.succeed(raw ? res : res.data),
      onFailure: err => {
        if (err.status === HttpStatusCode.Unauthorized) {
          return pipe(
            OAuthService.refreshToken(),
            Effect.mapError(() => err),
            Effect.flatMap(() => sendRequest<R, E>(request, raw)),
          );
        }

        return Effect.fail(err);
      },
    }),
    Effect.onExit(() => hideApiDownIndicator),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): Http.Effect<R, E>;
function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): Http.Effect<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): Http.Effect<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.get(url, config), raw);
}

function mapRangeResult<R = unknown, F extends Filter = {}, S extends Filter.Sort = null>(
  result: Omit<Range.Result<R, F, S>, 'cursorSize' | 'startIndex' | 'endIndex'>,
  cursor: Range.Cursor,
  filter: F,
  sort: S | null,
): Range.Result<R, F, S> {
  return {
    ...result,
    filter,
    sort,
    cursorSize: cursor.size,
    startIndex: cursor.startIndex,
    endIndex: cursor.endIndex,
  };
}

function getRange<R = unknown, F extends Filter = {}, S extends Filter.Sort = null, E = unknown>(
  url: string,
  page: number,
  filter: F,
  sort: S | null = null,
  size?: number,
  config?: AxiosRequestConfig,
): Http.Range<R, F, S, E> {
  const cursor = Range.Cursor.fromPage(page, size);

  return pipe(
    get<Omit<Range.Result<R, F, S>, 'cursorSize' | 'startIndex' | 'endIndex'>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...filter,
        ...cursor.queries,
        sort,
      },
    }),
    Effect.map(res => mapRangeResult(res, cursor, filter, sort)),
  );
}

function getNestedRange<
  Key extends string,
  F extends Filter,
  S extends Filter.Sort,
  R extends Range.NestedResult<Key, any, F, S>,
  E = unknown,
>(
  url: string,
  key: Key,
  page: number,
  filter: F,
  sort: S | null = null,
  size?: number,
  config?: AxiosRequestConfig,
): Http.Effect<R, E> {
  const cursor = Range.Cursor.fromPage(page, size);

  return pipe(
    get<R, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...filter,
        ...cursor.queries,
      },
    }),
    Effect.map(res => ({
      ...res,
      [key]: mapRangeResult(res[key], cursor, filter, sort),
    })),
  );
}

function removeEmptyStringOnBody(body?: any) {
  if (!(body instanceof FormData)) {
    return StringUtils.removeEmptyString(body);
  }

  return body;
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): Http.Effect<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): Http.Effect<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): Http.Effect<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.post(url, removeEmptyStringOnBody(data), config), raw);
}

function put<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): Http.Effect<R, E>;
function put<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): Http.Effect<AxiosResponse<R>, E>;

function put<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): Http.Effect<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.put(url, removeEmptyStringOnBody(data), config), raw);
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): Http.Effect<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): Http.Effect<AxiosResponse<R>, E>;

function del<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): Http.Effect<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.delete(url, config), raw);
}

export const HttpService = {
  get,
  getRange,
  getNestedRange,
  post,
  put,
  delete: del,
};
