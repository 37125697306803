import React, { ReactElement, ReactNode } from 'react';

import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';

import { Boolean, Function, Option } from 'effect';
import { RemoteData } from '@core/fp';
import { Http } from '@core/http';

export namespace RenderUtils {
  export function renderOptional<T, R1 extends ReactNode, R2 extends ReactNode>(
    value: Option.Option<T>,
    {
      onSome = Function.constNull,
      onNone = Function.constNull,
    }: Partial<{
      onSome: (data: T) => R1 | null;
      onNone: () => R2 | null;
    }>,
  ): R1 | R2 | null {
    return Option.match(value, { onSome, onNone });
  }

  export function renderNullable<T, R1 extends ReactNode = null, R2 extends ReactNode = null>(
    value: T | null | undefined,
    {
      onSome = Function.constNull,
      onNone = Function.constNull,
    }: Partial<{
      onSome: (data: T) => R1 | null;
      onNone: () => R2 | null;
    }>,
  ): R1 | R2 | null {
    return renderOptional(Option.fromNullable(value), { onSome, onNone });
  }

  export function renderConditional<R1 extends ReactNode = null, R2 extends ReactNode = null>(
    value: boolean,
    {
      onTrue = Function.constNull,
      onFalse = Function.constNull,
    }: Partial<{
      onTrue: () => R1 | null;
      onFalse: () => R2 | null;
    }>,
  ): R1 | R2 | null {
    return Boolean.match(value, { onFalse, onTrue });
  }

  export function renderHttpRemoteData<
    E,
    A,
    R1 extends ReactNode = null,
    R2 extends ReactNode = null,
    R3 extends ReactNode = null,
  >(
    value: Http.RemoteData<A, E>,
    {
      onPending = () => <DebouncedLineLoader />,
      onSuccess = Function.constNull,
      onFailure = error => <ErrorPage error={error} />,
    }: Partial<{
      onPending: () => R1 | ReactElement;
      onSuccess: (data: A) => R2 | null;
      onFailure: (error: Http.Error<E>) => R3 | ReactElement;
    }>,
  ): R1 | R2 | R3 | ReactElement | null {
    return RemoteData.match(value, { onPending, onSuccess, onFailure });
  }
}
