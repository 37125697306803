import { defineLoader, useParentLoader } from '@core/router/loader';
import { defineRoute, redirect } from '@core/router';
import { ProfileService } from '@modules/profile/service';
import { Effect, pipe, ReadonlyArray } from 'effect';
import { HttpStatusCode } from 'axios';
import { createPath, Outlet, resolvePath } from 'react-router-dom';
import { AuthUtils } from '@modules/auth/utils';
import { FC, useEffect } from 'react';
import { PushService } from '@shared/modules/push/service';
import { MobileService } from '@shared/modules/mobile/service';
import { Mobile } from '@shared/modules/mobile/model';
import { defaultMobileInstance } from '@core/mobile/config';

const LOADER_ID = 'profile-loader';

const profileLoader = defineLoader({
  id: LOADER_ID,
  handler: () =>
    pipe(
      ProfileService.getProfile(),
      Effect.matchEffect({
        onSuccess: Effect.succeed,
        onFailure: err => {
          if (HttpStatusCode.Unauthorized === err.status) {
            return redirect(createPath(resolvePath(AuthUtils.createLoginLink(true))));
          }

          return Effect.fail(err);
        },
      }),
    ),
});

const ProfileOutlet: FC = () => {
  useEffect(() => {
    pipe(
      Effect.all(
        ReadonlyArray.make(
          MobileService.postPermission(Mobile.Permission.Type.Notification),
          pipe(
            MobileService.getFirebase(),
            Effect.flatMap(token => PushService.register({ token, platform: defaultMobileInstance.platform! })),
          ),
        ),
        { concurrency: 'unbounded' },
      ),
      Effect.runPromiseExit,
    );
  }, []);

  return <Outlet />;
};

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
  element: <ProfileOutlet />,
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>(LOADER_ID);
}

export default profileLoaderRoute;
